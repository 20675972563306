.app-loading-div {
  margin-top: 100px;
  margin-bottom: 100px;
  margin: auto;
  width: 25%;
  text-align: center;
  padding: 50px;
}

@keyframes outer {
  50% {
    transform: translateY(0);
  }
  25%,
  75% {
    transform: translateY(4px);
  }
}
@keyframes body {
  50% {
    transform: rotate(16deg);
  }
  25%,
  75% {
    transform: rotate(24deg);
  }
}
@keyframes arm-leg {
  50% {
    transform: rotate(var(--r-to));
  }
}
@keyframes arm-b {
  30%,
  70% {
    transform: rotate(var(--r-to));
  }
}
