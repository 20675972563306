/* Re usable when color coding is implemented */
.rectangle-red {
  display: inline-flexbox;
  width: 25px;
  height: 25px;
  background: #d13313;
  margin: auto;
}

.rectangle-green {
  display: inline-flexbox;
  width: 25px;
  height: 25px;
  background: #41902c;
  margin: auto;
}

.rectangle-yellow {
  display: inline-flexbox;
  width: 25px;
  height: 25px;
  background: #ff9900;
  margin: auto;
}
