.create-form-input {
  width: 190px;
  margin-right: 20px;
}

.display-center {
  display: flex;
  justify-content: center;
}

.button-with-margin {
  margin-right: 10px;
}

.create-project-input {
  width: 200px;
}

.create-form-container {
  width: 75%;
}

.create-form-dropdown {
  width: 190px;
}

.create-form-multiselect-dropdown {
  width: calc(130% - 120px);
}

.create-form-input-description {
  width: calc(130% - 120px);
}
