/* main content except navbar and header  */
.main-content {
  min-height: calc(100vh - 105px);
  box-sizing: border-box;
}

.top-navigation {
  position: sticky;
  top: 0;
  z-index: 1002;
}
